import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { MovieByIdModal } from '../../types';
import { MoviePagePriceButton } from '../MovieInfo/components/MoviePageButtons/components/MoviePagePriceButton';
import { EnjoyVipButton } from './components/EnjoyVip';
import styles from './UpSellModal.module.scss';
import { BaseModal } from '@common/modals/modal';
import { PlayButton } from '@common/ui-components/buttons/PlayButton';
import { useStoreState } from '@store';
import AchtungIcon from '@assets/images/up-sell-modal/icon-achtung.png';
import VIP from '@assets/images/up-sell-modal/vip+.png';

type Props = {
  data: MovieByIdModal;
  showSvodMessage: boolean;
  hide: () => void;
  handleShowClubDeal: () => void;
  handleStreamButton: () => void;
  handleStreamPay: () => void;
  handleDownloadMovie: () => void;
  handleDownloadPay: () => void;
};

const UpSellModal: FC<Props> = ({
  showSvodMessage,
  data,
  handleStreamPay,
  handleDownloadPay,
  handleDownloadMovie,
  handleStreamButton,
  hide,
  handleShowClubDeal,
}) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useStoreState((state) => state.user);
  return (
    <BaseModal show={isAuthenticated && showSvodMessage} hide={hide}>
      <div id="VIP-POP-UP" className={styles.container}>
        <img src={AchtungIcon} alt="AchtungIcon" className={styles.warning_img} />
        <div className={styles.title_container}>
          <p className={styles.title}>{t('This film is in')}</p>
          <img src={VIP} alt="VIP +" className={styles.vip_img} />
          <p className={styles.title}>{t('Included')}</p>
        </div>
        <div className={styles.wrapper}>
          {data.needs_svod_button === 1 && data.is_svod && (
            <EnjoyVipButton
              fullPrice={data.promo_product.price ?? '0.00'}
              validDays={data.promo_product.days_valid ?? 30}
              handleClick={handleShowClubDeal}
            />
          )}
          <div className="-mt-3 mb-2 flex flex-row justify-center items-center">
            <hr style={{ width: '40%' }} />
            <p className={styles.or}>{t('or')}</p>
            <hr style={{ width: '40%' }} />
          </div>
          {(data.needs_svod_button === 0 && data.is_svod) || data.stream_button === 1 ? (
            <PlayButton handleClick={handleStreamButton} iconRight={true} text={`${t('Play')}`} />
          ) : (
            <MoviePagePriceButton
              text={`${t('Stream for')} 7 ${t('days')}`}
              price={{
                price: data.stream_price,
                oldPrice: data.stream_price_without_discount,
              }}
              handleClick={handleStreamPay}
            />
          )}
        </div>
        <div className="w-full mb-4">
          {data.download_button ? (
            <PlayButton text={`${t('Download for')} 2 ${t('days')}`} icon={false} handleClick={handleDownloadMovie} />
          ) : (
            <MoviePagePriceButton
              text={t('Download')}
              type="download"
              price={{
                price: data.download_price,
                oldPrice: data.download_price_without_discount,
              }}
              handleClick={handleDownloadPay}
            />
          )}
        </div>
      </div>
    </BaseModal>
  );
};

export default UpSellModal;
