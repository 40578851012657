import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MovieByIdModal } from '../../../../types';
import { MoviePagePriceButton } from './components/MoviePagePriceButton';
import { PlayButton } from '@common/ui-components/buttons/PlayButton';
import { JoinClubButton } from '@common/ui-components/buttons/JoinClubButton';

type Props = {
  data: MovieByIdModal;
  handleJoinClub: () => void;
  handleDownloadMovie: () => void;
  handleStreamPay: () => void;
  handleDownloadPay: () => void;
  handleClick: () => void;
};

const MoviePageButtons: FC<Props> = ({
  data,
  handleStreamPay,
  handleJoinClub,
  handleDownloadMovie,
  handleDownloadPay,
  handleClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="block">
        <div className="w-full mb-4">
          {data.needs_svod_button === 1 && data.is_svod && (
            <>
              {data.promo_product && (
                <JoinClubButton
                  fullPrice={data.promo_product.price}
                  validDays={data.promo_product.days_valid}
                  handleClick={handleJoinClub}
                />
              )}

              <div className="-mt-3 mb-2 flex flex-row justify-center items-center">
                <hr style={{ width: '40%' }} />
                <p className="mx-6 text-xl font-base">{t('or')}</p>
                <hr style={{ width: '40%' }} />
              </div>
            </>
          )}
          {(data.needs_svod_button === 0 && data.is_svod) || data.stream_button === 1 ? (
            <PlayButton handleClick={handleClick} iconRight={true} text={`${t('Play')}`} />
          ) : (
            <MoviePagePriceButton
              text={`${t('Stream for')} 7 ${t('days')}`}
              price={{
                price: data.stream_price,
                oldPrice: data.stream_price_without_discount,
              }}
              handleClick={handleStreamPay}
            />
          )}
        </div>
        <div className="w-full mb-4">
          {data.download_button ? (
            <PlayButton text={`${t('Download for')} 2 ${t('days')}`} icon={false} handleClick={handleDownloadMovie} />
          ) : (
            <MoviePagePriceButton
              text={t('Download')}
              type="download"
              price={{
                price: data.download_price,
                oldPrice: data.download_price_without_discount,
              }}
              handleClick={handleDownloadPay}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MoviePageButtons;
