import { PlanSelectModel } from '../../components/pages/unauthenticated/svod-deal/types';
import { DepositModalDataModel, ShowPhoneVerifyModel, UserInfoModel } from './types';

export const isDepositModal = (data: DepositModalDataModel | {}): data is DepositModalDataModel => {
  return (data as DepositModalDataModel).refetchData !== (undefined || null);
};

export const isPhoneVerifyModel = (data: ShowPhoneVerifyModel | {}): data is ShowPhoneVerifyModel => {
  return (data as ShowPhoneVerifyModel).method !== (undefined || null);
};

export const isPlanSelectModel = (data: PlanSelectModel | {}): data is PlanSelectModel => {
  return (data as PlanSelectModel).data.id !== (undefined || null);
};

export const isUserInfoModel = (data: UserInfoModel | {}): data is UserInfoModel => {
  return (data as UserInfoModel).id !== (undefined || null);
};
