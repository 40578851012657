import http from '@http';
import toResponse from '@helpers/toResponse/toResponse';

export const getMovies = async (queryKey, { params }) => {
  const { data } = await http.get(`/movies`, { params });
  return data;
};

export const getTop100Movies = async (queryKey, { params }) => {
  const { data } = await http.get(`/top-100-movies`, { params });
  return data;
};

export const getMovieById = async ({ id, promo_code }) => {
  const { data } = await http.get(`/movies/${id}${promo_code ? `?promo_code=${promo_code}` : ''}`);
  return data;
};

export const getMyMovies = async (queryKey, { params }) => {
  const { data } = await http.get(`/histories`, { params });
  return data;
};

export const getPromoMovies = async ({ id, params }) => {
  const { data } = await http.get(`/promotions/movies/${id}`, { params });
  return data;
};

export const updateStreamTime = (values) => toResponse(http.post('/update-time', values));

export const rateMovie = (values) => toResponse(http.post('/rate-movie', values));

export const getRandomMovies = (queryKey, {params}) => toResponse(http.get('/movies-random', { params }));