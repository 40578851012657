import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  text?: string;
  bgColor: string;
  textColor: string;
  loading?: boolean;
  handleClick?: () => void;
  type?: 'submit' | 'reset' | 'button' | undefined;
  icon?: any
  className?: any
  disabled?: boolean
};

const FormButton: FC<Props> = ({ text, bgColor, handleClick, type, textColor, loading = false, icon, className, disabled }) => {
  const { t } = useTranslation();
  const Icon = useMemo(()=> icon,[icon]);

  const onClick = useCallback(() => {
    if(loading || disabled) return;
    handleClick && handleClick();
  },[handleClick, loading, disabled]);
  
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${(loading || disabled) && 'cursor-not-allowed'} relative form-buttons spinner-button text-${textColor} shadow-lg font-semibold bg-${bgColor} border-0 py-2 px-6 focus:outline-none rounded text-lg w-full mt-2${!text ? ' no-text' : ''} ${className}`}
      disabled={disabled}
    >
      {Icon ? <Icon /> : null}
      <div className={`${loading && 'btnInnerSpinner'} ${textColor === 'black' && 'blackSpinner'}`}></div>
      {text && <div className={`label ${loading ? 'opacity-0' : ''}`}>{t(text)}</div>}
    </button>
  );
};

export default FormButton;
